import { createConnectedStore, Store } from "undux";

export type StoreType = {
  orgName: string;
  orgId: string;
  nonce: string;
  publicKey: string;
  origin: string;
  isSandbox: boolean;
  keyInvalid: boolean;
  platforms: any[];
  organization: Organization | null;
  isUpdate: boolean; // if this an existing connection (or created by POST /connections/create)
  itemId: string; // the itemId for the existing connection, set in ConnectionContainer
  shopifyStoreUrl: string;
  bigCommerceStoreUrl: string;
  autoPlatform: string | null;
  autoShopifyStore: string | null;
  customRedirectUrl: string;
};

type Organization = {
  id: string;
  name: string;
  emergencyDeveloperEmail: string;
  privateShopifyScopes: string;
  isPrivateShopify: boolean;
  bigCommerceScopes: string;
  isPrivateBigCommerce: boolean;
  linkLogoUrl: string | null;
  linkText: string | null;
  platformPageText?: string;
  additionalPrivacyPolicyLink?: string;
  additionalTermsLink?: string;
};

// Create a store with an initial value.
export default createConnectedStore<StoreType>({
  orgName: "",
  orgId: "",
  nonce: "",
  publicKey: "",
  origin: "",
  isSandbox: false,
  keyInvalid: false,
  platforms: [],
  organization: null,
  shopifyStoreUrl: "",
  bigCommerceStoreUrl: "",
  itemId: "",
  isUpdate: false,
  autoPlatform: null,
  autoShopifyStore: null,
  customRedirectUrl: "",
});
