export default function RutterLogo() {
  return (
    <svg width="48" height="48" viewBox="0 0 64 64" fill="none">
      <path
        d="M20.3496 43.6504L28.1156 35.8844L12.5837 20.3496C11.5539 19.3198 10.9753 17.9231 10.9753 16.4667C10.9753 15.0103 11.5539 13.6135 12.5837 12.5837C13.6135 11.5539 15.0103 10.9753 16.4667 10.9753C17.9231 10.9753 19.3198 11.5539 20.3496 12.5837L28.1156 4.81777C25.0233 1.74177 20.8373 0.0176023 16.4757 0.0233603C12.114 0.0291183 7.93265 1.76433 4.84849 4.84849C1.76433 7.93265 0.0291183 12.114 0.0233603 16.4757C0.0176023 20.8373 1.74177 25.0233 4.81777 28.1156L20.3496 43.6504Z"
        fill="#0D1426"
      />
      <path
        d="M28.1156 28.1156L43.6504 12.5837C44.1603 12.0738 44.7657 11.6693 45.4319 11.3933C46.0981 11.1174 46.8122 10.9753 47.5333 10.9753C48.2545 10.9753 48.9685 11.1174 49.6348 11.3933C50.301 11.6693 50.9064 12.0738 51.4163 12.5837C51.9262 13.0936 52.3307 13.699 52.6067 14.3652C52.8826 15.0315 53.0247 15.7455 53.0247 16.4667C53.0247 17.1878 52.8826 17.9019 52.6067 18.5681C52.3307 19.2343 51.9262 19.8397 51.4163 20.3496L59.1822 28.1156C62.2582 25.0233 63.9824 20.8373 63.9766 16.4757C63.9709 12.114 62.2357 7.93265 59.1515 4.84849C56.0674 1.76433 51.886 0.0291183 47.5243 0.0233603C43.1627 0.0176023 38.9767 1.74177 35.8845 4.81777L20.3496 20.3496L28.1156 28.1156Z"
        fill="#0D1426"
      />
      <path
        d="M43.6504 20.3496L35.8845 28.1156L51.4163 43.6504C51.9262 44.1603 52.3307 44.7657 52.6067 45.4319C52.8827 46.0981 53.0247 46.8122 53.0247 47.5333C53.0247 48.2545 52.8827 48.9685 52.6067 49.6348C52.3307 50.301 51.9262 50.9064 51.4163 51.4163C50.9064 51.9262 50.301 52.3307 49.6348 52.6067C48.9686 52.8826 48.2545 53.0247 47.5334 53.0247C46.8122 53.0247 46.0981 52.8826 45.4319 52.6067C44.7657 52.3307 44.1603 51.9262 43.6504 51.4163L35.8845 59.1822C38.9768 62.2582 43.1627 63.9824 47.5243 63.9766C51.886 63.9709 56.0674 62.2357 59.1515 59.1515C62.2357 56.0674 63.9709 51.886 63.9767 47.5243C63.9824 43.1627 62.2583 38.9767 59.1822 35.8845L43.6504 20.3496Z"
        fill="#0D1426"
      />
      <path
        d="M35.8844 35.8844L20.3496 51.4163C19.8397 51.9262 19.2343 52.3307 18.5681 52.6067C17.9019 52.8826 17.1878 53.0247 16.4667 53.0247C15.7455 53.0247 15.0315 52.8826 14.3652 52.6067C13.699 52.3307 13.0936 51.9262 12.5837 51.4163C12.0738 50.9064 11.6693 50.301 11.3933 49.6348C11.1174 48.9685 10.9753 48.2545 10.9753 47.5333C10.9753 46.8122 11.1174 46.0981 11.3933 45.4319C11.6693 44.7657 12.0738 44.1603 12.5837 43.6504L4.81777 35.8844C1.74177 38.9767 0.0176023 43.1627 0.0233603 47.5243C0.0291183 51.886 1.76433 56.0673 4.84849 59.1515C7.93265 62.2357 12.114 63.9709 16.4757 63.9766C20.8373 63.9824 25.0233 62.2582 28.1156 59.1822L43.6504 43.6504L35.8844 35.8844Z"
        fill="#0D1426"
      />
    </svg>
  );
}
