import { ArrowLeftOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Alert, Button, Input } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { captureException } from "../sentry";
import Store from "../stateStore";
import { getBackendUrl, STORES } from "../utils";
import qs from "query-string";
import PlatformPageText from "./shared/PlatformPageText";
import TextUnderConnect from "./shared/TextUnderConnect";

type Props = {
  clientSecret: string;
  onBack: () => any;
  onCancel: () => any;
  onComplete: any;
};

function WooCommerce(props: Props) {
  const { onComplete, clientSecret } = props;

  const stateStore = Store.useStore();
  const isSandbox = stateStore.get("isSandbox");
  const organizationId = stateStore.get("orgId");
  const nonce = stateStore.get("nonce");
  const origin = stateStore.get("origin");
  const autoPlatform = stateStore.get("autoPlatform");
  const itemId = stateStore.get("itemId");
  const org = stateStore.get("organization");

  const [url, setUrl] = useState(isSandbox ? "shop_good" : "");
  const [username, setUsername] = useState(isSandbox ? "user_good" : "");
  const [password, setPassword] = useState(isSandbox ? "pass_good" : "");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleAuthError = (e: any) => {
    captureException(e);
    if (e.response?.data?.error_message) {
      setError(e.response.data?.error_message);
    } else {
      setError(
        "An error occurred, but your credentials are secure. Please try again, or contact support."
      );
    }
  };

  const handleFakeComplete = () => {
    setLoading(true);
    axios
      .post(`${getBackendUrl()}/link/token/fulfill`, {
        type: STORES.WOO_COMMERCE,
        clientId: clientSecret,
        website: url,
        username: username,
        password: password,
      })
      .then((response) => {
        const { data } = response;
        const { public_token } = data;
        onComplete(public_token);
      })
      .catch(handleAuthError)
      .finally(() => {
        setLoading(false);
      });
  };

  const handleComplete = () => {
    setLoading(true);
    let cleanStoreUrl = url;
    if (cleanStoreUrl.includes("https://")) {
      cleanStoreUrl.replace("https://", "");
    }
    if (cleanStoreUrl.includes("http://")) {
      cleanStoreUrl.replace("http://", "");
    }
    const q = qs.stringify({
      org: organizationId,
      store_url: cleanStoreUrl,
      link_update_id: itemId ?? undefined,
    });
    const redirectUrl = `${getBackendUrl()}/woocommerce/app-url?${q}`;
    localStorage.setItem(
      "rutterlinkstate",
      JSON.stringify({
        currentPlatform: STORES.WOO_COMMERCE,
        origin,
        nonce,
      })
    );
    window.location.replace(redirectUrl);
  };

  return (
    <div style={{ padding: "1.6rem" }}>
      <div style={{ display: "flex" }} className="">
        {!autoPlatform && (
          <Button
            onClick={props.onBack}
            icon={<ArrowLeftOutlined />}
            className="flex items-center"
          >
            Back
          </Button>
        )}
      </div>
      <div
        className="flex items-center flex-column mt-4"
        style={{ justifyContent: "center", paddingBottom: "20px" }}
      >
        <img
          style={{ height: "80px" }}
          src="https://rutterpublicimages.s3.us-east-2.amazonaws.com/woologo.png"
          alt=""
        />
      </div>
      {org?.platformPageText && (
        <div className="mb-2 text-xs">
          <PlatformPageText></PlatformPageText>
        </div>
      )}
      <div className="font-semibold">Store URL</div>
      <div className="text-sm">
        Please enter in your WooCommerce domain. If your store is at
        www.mystore.com, then enter in "www.mystore.com".
      </div>
      <div style={{ marginBottom: 16 }} className="mt-2">
        <Input
          addonBefore="https://"
          placeholder="Store URL"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />
      </div>
      {isSandbox ? (
        <Button
          type="primary"
          className=""
          block
          disabled={loading}
          onClick={handleFakeComplete}
        >
          {loading ? "Connecting..." : "Sandbox: Connect Test WooCommerce"}
        </Button>
      ) : (
        <Button
          type="primary"
          block
          disabled={loading || url === ""}
          onClick={handleComplete}
        >
          {loading ? "Connecting..." : "Connect WooCommerce"}
        </Button>
      )}
      {(org?.additionalPrivacyPolicyLink || org?.additionalTermsLink) && (
        <div className="mt-2 text-xs">
          <TextUnderConnect></TextUnderConnect>
        </div>
      )}
      {error && (
        <Alert
          className="mt-4"
          type="error"
          message={"Error"}
          description={error}
          showIcon
        ></Alert>
      )}
    </div>
  );
}

export default WooCommerce;

const Container = styled.div``;
