import { Button, Result } from "antd";
import qs from "query-string";
import React from "react";
import { useHistory } from "react-router-dom";
import { captureException } from "./sentry";

function LinkComplete() {
  const history = useHistory();
  const { search } = history.location;
  const parsedSearch = qs.parse(search);

  const { origin, nonce, public_token } = parsedSearch;

  React.useEffect(() => {
    if (public_token && nonce && origin) {
      if (window.opener) {
        try {
          window.opener.postMessage(
            JSON.stringify({
              publicToken: public_token,
              nonce,
              type: "SUCCESS",
            }),
            origin
          );
        } catch (e) {
          captureException(e);
        }
      }
    }
  }, []);

  const handleClose = () => {
    try {
      window.close();
    } catch (e) {
      captureException(e);
      window.close();
    }
  };

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 99,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Result
        status="success"
        title="Authentication Complete!"
        subTitle=""
        extra={[
          <Button type="primary" key="console" onClick={handleClose}>
            Close window
          </Button>,
        ]}
      />
    </div>
  );
}

export default LinkComplete;
