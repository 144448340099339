import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import LogRocket from "logrocket";
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import BigCommerceComplete from "./bigcommerce/LinkComplete";
import ConnectionContainer from "./container/ConnectionContainer";
import UpdateComplete from "./container/UpdateComplete";
import LinkComplete from "./LinkComplete";
import LinkContainer from "./LinkContainer";
import LinkError from "./LinkError";
import ShopifyCustomAppComplete from "./shopify/CustomAppLinkComplete";
import Store from "./stateStore";
import "./tailwind.output.css";
import WooCommerceComplete from "./woocommerce/LinkComplete";

LogRocket.init("rutter/rutter-link");
Sentry.init({
  dsn: "https://e2b2bb62e22b42079c92b3bb4afbe093@o271524.ingest.sentry.io/5602474",
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.NODE_ENV,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  normalizeDepth: 8,
});

function App(props: any) {
  return (
    <Store.Container>
      <Router>
        <Switch>
          <Route exact path="/complete">
            <LinkComplete></LinkComplete>
          </Route>
          <Route exact path="/updatecomplete">
            <UpdateComplete></UpdateComplete>
          </Route>
          <Route exact path="/bigcommercecomplete">
            <BigCommerceComplete></BigCommerceComplete>
          </Route>
          <Route exact path="/shopifycustomcomplete">
            <ShopifyCustomAppComplete></ShopifyCustomAppComplete>
          </Route>
          <Route exact path="/woocommercecomplete">
            <WooCommerceComplete></WooCommerceComplete>
          </Route>
          <Route exact path="/error">
            <LinkError></LinkError>
          </Route>
          <Route path="/connection/:id">
            <ConnectionContainer></ConnectionContainer>
          </Route>
          <Route path="/connections/:id">
            <ConnectionContainer></ConnectionContainer>
          </Route>
          <Route>
            <LinkContainer></LinkContainer>
          </Route>
        </Switch>
      </Router>
    </Store.Container>
  );
}

export default App;
