import { StoreType } from "./stateStore";
import axios from "axios";
import { Store } from "undux";

export function isProd() {
  return process.env.NODE_ENV === "production";
}

export function isDev() {
  return process.env.NODE_ENV === "development";
}

export function getBackendUrl() {
  return isProd()
    ? "https://production.rutterapi.com"
    : process.env.REACT_APP_TUNNEL_ON
    ? "https://rutter.ngrok.io"
    : "http://localhost:4000";
}

const RUTTER_BACKEND_URL = isProd()
  ? "https://production.rutterapi.com"
  : "http://localhost:4000";

export enum STORES {
  SHOPIFY = "SHOPIFY",
  MAGENTO = "MAGENTO",
  BIG_COMMERCE = "BIG_COMMERCE",
  WOO_COMMERCE = "WOO_COMMERCE",
  SQUARESPACE = "SQUARESPACE",
  WIX = "WIX",
  COMMERCELAYER = "COMMERCELAYER",
  SQUARE = "SQUARE",
  AMAZON = "AMAZON",
  EBAY = "EBAY",
  ETSY = "ETSY",
  PRESTASHOP = "PRESTASHOP",
}

export async function attemptAuthorization(credentials: {
  clientId: string;
  publicKey: string;
  type: STORES;
  website: string | undefined;
  username: string | undefined;
  password: string | undefined;
}) {
  const response = await axios.post(
    RUTTER_BACKEND_URL + "/link/token/fulfill",
    {
      clientId: credentials.clientId,
      publicKey: credentials.publicKey,
      type: credentials.type,
      website: credentials.website || null,
      username: credentials.username || null,
      password: credentials.password || null,
    }
  );

  return "OK";
}

export function extractLinkParamsFromState(state: Store<StoreType>) {
  return {
    link_update_id: state.get("itemId") ?? undefined,
    org_id: state.get("orgId") ?? undefined,
    nonce: state.get("nonce") ?? undefined,
    origin: state.get("origin") ?? undefined,
    custom_redirect_url: state.get("customRedirectUrl") ?? undefined,
  };
}
