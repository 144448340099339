import qs from "qs";
import React, { useEffect, useState } from "react";
import { captureException } from "../sentry";
import Store from "../stateStore";
import { extractLinkParamsFromState, getBackendUrl } from "../utils";

type Props = {
  clientSecret: string;
  onBack: () => any;
  onCancel: () => any;
  onComplete: any;
};

function Ebay(props: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const stateStore = Store.useStore();
  const isSandbox = stateStore.get("isSandbox");
  const organizationId = stateStore.get("orgId");
  const nonce = stateStore.get("nonce");
  const origin = stateStore.get("origin");

  useEffect(() => {
    const openEbayAuth = () => {
      let result: any;
      setIsLoading(true);
      try {
        const q = qs.stringify({
          ...extractLinkParamsFromState(stateStore),
        });
        const url = `${getBackendUrl()}/ebay/link?${q}`;
        window.location.href = url;
      } catch (e) {
        handleAuthError(e);
        setIsLoading(false);
        return;
      }
    };

    openEbayAuth();
    return () => {};
  });

  const handleAuthError = (e: any) => {
    captureException(e);
    if (e.response?.data?.error_message) {
      setError(e.response.data?.error_message);
    } else {
      setError(
        "An error occurred, but your credentials are secure. Please try again, or contact support."
      );
    }
  };

  return <div></div>;
}

export default Ebay;
