import { ArrowLeftOutlined } from "@ant-design/icons";
import { Alert, Button } from "antd";
import axios from "axios";
import qs from "query-string";
import React, { useState } from "react";
import { captureException } from "../sentry";
import Store from "../stateStore";
import { extractLinkParamsFromState, getBackendUrl, STORES } from "../utils";

type Props = {
  clientSecret: string;
  onBack: () => any;
  onCancel: () => any;
  onComplete: any;
};

function Etsy(props: Props) {
  const { onComplete, clientSecret } = props;

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const store = Store.useStore();
  const isSandbox = store.get("isSandbox");
  const orgName = store.get("orgName");
  const organizationId = store.get("orgId");
  const nonce = store.get("nonce");
  const origin = store.get("origin");
  const autoPlatform = store.get("autoPlatform");

  const isUpdate = store.get("isUpdate");
  const itemId = store.get("itemId");

  const handleAuthError = (e: any) => {
    captureException(e);
    if (e.response?.data?.error_message) {
      setError(e.response.data?.error_message);
    } else {
      setError(
        "An error occurred, but your credentials are secure. Please try again, or contact support."
      );
    }
  };

  const handleComplete = () => {
    // redirect to oauth
    setLoading(true);
    // const query = {
    //   linknonce: nonce,
    //   linkorigin: origin,
    //   link_update_id: itemId,
    // };
    const q = qs.stringify({
      ...extractLinkParamsFromState(store),
    });
    const url = `${getBackendUrl()}/etsy/proxy/${organizationId}/app-url?${q}`;
    window.location.replace(url);
  };

  const handleFakeComplete = () => {
    // skip oauth and get fake credentials
    setLoading(true);
    axios
      .post(`${getBackendUrl()}/link/token/fulfill`, {
        type: STORES.SQUARE,
        clientId: clientSecret,
        username: "user_good",
        password: "pass_good",
      })
      .then((response) => {
        const { data } = response;
        const { public_token } = data;
        // NEED OT PASS THIS BACK SOMEHOW
        onComplete(public_token);
      })
      .catch(handleAuthError)
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div style={{ padding: "1.6rem" }}>
      {!autoPlatform && (
        <div style={{ display: "flex" }} className="">
          <Button
            onClick={props.onBack}
            icon={<ArrowLeftOutlined />}
            className="flex items-center"
          >
            Back
          </Button>
        </div>
      )}
      <div
        className="flex items-center flex-column mt-4"
        style={{ justifyContent: "center", paddingBottom: "20px" }}
      >
        <img
          style={{ height: "80px" }}
          src="https://rutterpublicimages.s3.us-east-2.amazonaws.com/etsysquare.png"
          alt=""
        />
      </div>
      <div className="text-center pb-4">
        Connect your Etsy account with {orgName} by clicking the button below.
      </div>
      <div className="text-center pb-4">
        The term 'Etsy' is a trademark of Etsy, Inc. This application uses the
        Etsy API but is not endorsed or certified by Etsy, Inc.{" "}
      </div>
      {isSandbox ? (
        <Button
          type="primary"
          block
          disabled={loading}
          onClick={handleFakeComplete}
        >
          {loading ? "Connecting..." : "Sandbox: Connect Test Etsy"}
        </Button>
      ) : (
        <Button
          type="primary"
          block
          disabled={loading}
          onClick={handleComplete}
        >
          {loading ? "Connecting..." : "Connect Etsy"}
        </Button>
      )}

      {error && (
        <Alert
          className="mt-4"
          type="error"
          message={"Error"}
          description={error}
          showIcon
        ></Alert>
      )}
    </div>
  );
}

export default Etsy;
