import React from "react";
import Store from "./stateStore";
import { useHistory, useParams, Switch, Route } from "react-router-dom";
import qs, { parse } from "query-string";
import { Result, Button, Typography } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

const { Paragraph, Text } = Typography;

function LinkComplete() {
  const history = useHistory();
  const { search } = history.location;
  const parsedSearch = qs.parse(search);
  const { error_message, link_origin } = parsedSearch;

  const handleTryAgain = () => {
    history.push("/platform");
  };

  const handleClose = () => {
    window.close();
  };

  let actions: any = [
    <Button key="buy" onClick={handleClose}>
      Close window
    </Button>,
  ];
  if (link_origin) {
    actions = [
      <Button type="primary" key="console" onClick={handleTryAgain}>
        Try again
      </Button>,
      ...actions,
    ];
  }

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 99,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Result
        status="error"
        title="Authentication Error"
        subTitle={
          "We encountered an error, but your information & credentials are secure."
        }
        extra={actions}
      >
        <div className="desc">
          <Paragraph>
            <Text
              strong
              style={{
                fontSize: 16,
              }}
            >
              Here's what we know:
            </Text>
          </Paragraph>
          <Paragraph>{error_message}</Paragraph>
        </div>
      </Result>
    </div>
  );
}

export default LinkComplete;
