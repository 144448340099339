import Store from "../../stateStore";
import { Alert } from "antd";

export default function TextUnderConnect() {
  let stateStore = Store.useStore();
  const org = stateStore.get("organization")!;
  const { additionalPrivacyPolicyLink, additionalTermsLink } = org;

  if (additionalTermsLink && !additionalPrivacyPolicyLink) {
    return (
      <div className="text-center">
        By connecting your store to {org.name}, you agree to the {org.name}{" "}
        <a href={additionalTermsLink}>Terms & Conditions</a>
      </div>
    );
  }
  if (!additionalTermsLink && additionalPrivacyPolicyLink) {
    return (
      <div>
        By connecting your store to {org.name}, you agree to the {org.name}{" "}
        <a href={additionalPrivacyPolicyLink}>Privacy Policy</a>
      </div>
    );
  }
  if (additionalPrivacyPolicyLink && additionalTermsLink) {
    return (
      <div className="text-center">
        By connecting your store to {org.name}, you agree to the {org.name}{" "}
        <a href={additionalTermsLink}>Terms & Conditions </a>
        and <a href={additionalPrivacyPolicyLink}> Privacy Policy</a>
      </div>
    );
  }

  return null;
}
