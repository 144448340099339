import { ArrowLeftOutlined } from "@ant-design/icons";
import { Alert, Button, Input } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { captureException } from "../sentry";
import Store from "../stateStore";
import { getBackendUrl, STORES } from "../utils";

type Props = {
  clientSecret: string;
  onBack: () => any;
  onCancel: () => any;
  onComplete: any;
};

function BigCommerce(props: Props) {
  const { onComplete, clientSecret } = props;

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const stateStore = Store.useStore();
  const isSandbox = stateStore.get("isSandbox");
  const organizationId = stateStore.get("orgId");
  const org = stateStore.get("organization");
  const nonce = stateStore.get("nonce");
  const origin = stateStore.get("origin");
  const autoPlatform = stateStore.get("autoPlatform");
  const [website, setWebsite] = useState("");
  const history = useHistory();

  const handleAuthError = (e: any) => {
    captureException(e);
    if (e.response?.data?.error_message) {
      setError(e.response.data?.error_message);
    } else {
      setError(
        "An error occurred, but your credentials are secure. Please try again, or contact support."
      );
    }
  };

  const handleFakeComplete = () => {
    setLoading(true);
    axios
      .post(`${getBackendUrl()}/link/token/fulfill`, {
        type: STORES.BIG_COMMERCE,
        clientId: clientSecret,
        username: "user_good",
        password: "pass_good",
      })
      .then((response) => {
        const { data } = response;
        const { public_token } = data;
        onComplete(public_token);
      })
      .catch(handleAuthError)
      .finally(() => {
        setLoading(false);
      });
  };

  const handleComplete = () => {
    if (org?.isPrivateBigCommerce) {
      stateStore.set("bigCommerceStoreUrl")(website);
      history.push("/bigcommerceapiaccount");
      return;
    }
    setLoading(true);
    localStorage.setItem(
      "rutterlinkstate",
      JSON.stringify({
        currentPlatform: "BIG_COMMERCE",
        origin,
        nonce,
      })
    );
    const url = `${getBackendUrl()}/bigcommerce/proxy/${organizationId}/app-url`;
    window.location.replace(url);
  };

  return (
    <div style={{ padding: "1.6rem" }}>
      <div style={{ display: "flex" }} className="">
        {!autoPlatform && (
          <Button
            onClick={props.onBack}
            icon={<ArrowLeftOutlined />}
            className="flex items-center"
          >
            Back
          </Button>
        )}
      </div>
      <div
        className="flex items-center flex-column mt-4"
        style={{ justifyContent: "center", paddingBottom: "20px" }}
      >
        <img
          style={{ height: "80px" }}
          src="https://rutterpublicimages.s3.us-east-2.amazonaws.com/bigcommercelogo.webp"
          alt=""
        />
      </div>
      <div className="font-semibold">Store URL</div>
      <div className="text-sm">
        Please enter in your BigCommerce url. If your store is at
        mystore.mybigcommerce.com, then enter in "mystore.mybigcommerce.com".
      </div>
      <div style={{ marginBottom: 16 }} className="mt-2">
        <Input
          addonBefore="https://"
          placeholder="Store URL"
          value={website}
          onChange={(e) => setWebsite(e.target.value)}
        />
      </div>
      {isSandbox ? (
        <Button
          type="primary"
          className=""
          block
          disabled={loading}
          onClick={handleFakeComplete}
        >
          {loading ? "Connecting..." : "Sandbox: Connect Test BigCommerce"}
        </Button>
      ) : (
        <Button
          type="primary"
          block
          disabled={loading}
          onClick={handleComplete}
        >
          {loading ? "Connecting..." : "Connect BigCommerce"}
        </Button>
      )}
      {error && (
        <Alert
          className="mt-4"
          type="error"
          message={"Error"}
          description={error}
          showIcon
        ></Alert>
      )}
      {error && (
        <Alert
          className="mt-4"
          type="error"
          message={"Error"}
          description={error}
          showIcon
        ></Alert>
      )}
    </div>
  );
}

export default BigCommerce;
