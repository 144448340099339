import { Spin, Alert } from "antd";
import axios from "axios";
import qs from "query-string";
import React from "react";
import { useHistory, useParams, Switch, Route } from "react-router-dom";
import Link from "./Link";
import { getBackendUrl } from "./utils";
import Store from "./stateStore";
import LinkComplete from "./LinkComplete";
import LinkError from "./LinkError";
import { captureException } from "./sentry";
import LogRocket from "logrocket";

function LinkContainer() {
  const history = useHistory();
  const { search } = history.location;
  const parsedSearch = qs.parse(search);
  const { token, origin, nonce, platform } = parsedSearch; // token is the publicKey, prefixed with sandbox
  let store = Store.useStore();
  const [loading, setLoading] = React.useState(false);
  const [orgName, setOrgName] = React.useState("This app");
  const [orgId, setOrgId] = React.useState("");
  const [error, setError] = React.useState("");

  const isSandbox = (token as string)?.startsWith("sandbox");

  React.useEffect(() => {
    // effect run once
    store.set("publicKey")(token as string);
    store.set("nonce")(nonce as string);
    store.set("origin")(origin as string);
    store.set("isSandbox")(isSandbox);
    if (platform) {
      store.set("autoPlatform")(platform as string);
    }

    setLoading(true);
    axios
      .get(`${getBackendUrl()}/link/init`, {
        params: {
          clientId: token,
        },
      })
      .then((response) => {
        const { data } = response;
        const { organization, platforms, error } = data;
        if (error?.message === "Invalid clientId") {
          store.set("keyInvalid")(true);
        }
        const org = {
          ...organization,
          id: organization.id,
          name: organization.name,
          emergencyDeveloperEmail: organization.emergencyDeveloperEmail,
          privateShopifyScopes: organization.privateShopifyScopes,
          isPrivateShopify: organization.isPrivateShopify,
          bigCommerceScopes: organization.bigCommerceScopes,
          isPrivateBigCommerce: organization.isPrivateBigCommerce,
          linkLogoUrl: organization.linkLogoUrl,
          linkText: organization.linkText,
          platformPageText: organization.platformPageText,
          additionalPrivacyPolicyLink: organization.additionalPrivacyPolicyLink,
        };
        setOrgName(organization.name);
        setOrgId(organization.id);
        store.set("platforms")(platforms);
        store.set("orgId")(organization.id as string);
        store.set("orgName")(organization.name as string);
        store.set("organization")(org);
        // do local storage...

        // identify the session?
        LogRocket.identify(org.id, {
          name: org.name,
        });
      })
      .catch((e) => {
        captureException(e);
        if (e.message === "Network Error") {
          // user/dev
          setError("Error connecting to Rutter. Please try again later.");
        } else if (e?.response?.data?.error === "INVALID_PUBLIC_KEY") {
          // dev
          setError(
            "Invalid Rutter public key. To find your public key, visit https://dashboard.rutterapi.com"
          );
        } else {
          setError(
            "An unexpected error occurred, and we're looking into it. Please try again later."
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 99,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin></Spin>
      </div>
    );
  }

  if (error) {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 99,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Alert
          type="error"
          message={"Error"}
          description={error}
          showIcon
        ></Alert>
      </div>
    );
  }

  return (
    <>
      <Switch>
        <Route path="/">
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 99,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Link
              customerAction="connect to your store"
              nonce={nonce as string}
              origin={origin as string}
              customerName={orgName}
              organizationId={orgId as string}
              clientSecret={token as string}
              onSuccess={() => {}}
              onCancel={() => {
                // cancel the iframe
              }}
              onError={() => {}}
            />

            {isSandbox && (
              <div className="mt-4 bg-black p-2 text-white rounded">
                You are currently in Sandbox mode.
              </div>
            )}
          </div>
        </Route>
      </Switch>
    </>
  );
}

export default LinkContainer;
